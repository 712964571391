import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"

import pageCss from "@css/components/common/page.module.styl"
import missionCss from "@css/components/recruit/mission.module.styl"
import memberCss from "@css/components/recruit/member.module.styl"
import wantedCss from "@css/components/recruit/wanted.module.styl"

import Footer from '@components/Footer'

class Privacy extends React.PureComponent {
    render(){
        const { data } = this.props
        const { bg01 } = data
        return(
            <div>
                <SEO title="利用規約" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <h1 className={pageCss.title_mid}>
                                    利用規約
                                </h1>
                            </div>
							<div className={pageCss.term}>
                                <pre>
{`
SUGAR利用規約（以下「本規約」といいます）は、SUGAR株式会社（以下「当社」といいます）が提供するライブ配信アプリケーションサービス「SUGAR（シュガー）」（以下「本サービス」といいます）における利用条件を定めるものです。

第1章　総則

第1.1条（定義）
本規約では、以下の用語を使用します。
(1)　「本アプリ」とは、本サービスにおいて提供されるアプリケーションのことをいいます。
(2)　「コンテンツ」とは、文章、音声、音楽、画像、動画、ソフトウェア、プログラム、コードその他の情報のことをいいます。
(3)　「本コンテンツ」とは、本サービスを通じてアクセスすることができるコンテンツのことをいいます。
(4)　「投稿コンテンツ」とは、ユーザーが本アプリに投稿、送信、配信又はアップロードするコンテンツのことをいいます。
(5)　「アカウント」とは、SUGAR会員登録時に、ユーザーが定め、当社が発行するユーザーを識別するための文字列その他の標識をいい、「ユーザーID」及び「パスワード」を含みます。
(6)　「アプリ内ポイント」とは、本サービス内において有償で提供されるサービス又はコンテンツに使用できる電子的な仮想通貨のことをいいます。
(7)　「月額サービス」とは、本サービス内において月額利用料金を支払うことに提供されるサービス又はコンテンツのことをいいます。
(8)　「月額会員」とは、「月額サービス」を利用している会員のことをいいます。
(9)　「プレミアムサービス」とは、特定のユーザーが提供している「月額サービス」のことをいいます。
(10)　「プレミアムサービス提供元」とは、「プレミアムサービス」を提供しているユーザーのことをいいます。
(11)　「有償サービス」とは、本サービスにおいて、ユーザーに有償で提供される（有償又は無償で付与されるアプリ内ポイントと交換される場合を含みます）サービス又はコンテンツのことをいいます。
(12)　「個別規約」とは、本サービスに関して、本規約とは別に「規約」、「ガイドライン」、「ポリシー」などの名称で当社が配布又は掲示している文書のことをいいます。
(13)　「本利用契約」とは、本規約に基づく当社とユーザーとの間の契約をいいます。

第1.2条（本規約）
1　ユーザーが、本サービスの利用（アプリケーションのダウンロードを含みます）を行う場合、本規約に同意する必要があります。
2　当社は、本サービスの利用に関し、本規約のほか、個別規約を定める場合があります。この場合には、当該個別規約は、本規約の一部を構成するものとします。本規約の内容と個別規約の内容が異なる場合には、原則として個別規約の内容が優先して適用されるものとします。
3　ユーザーが本サービスを利用した時点で本規約に同意したものとみなされます。

第1.3条（未成年者による利用）
1　ユーザーが未成年者である場合、ユーザーは、本サービスの利用（本規約への同意を含みます）について、あらかじめ法定代理人（親権者等）の同意を得て、本サービスを利用するものとします。ユーザーが当該同意を得ずに本サービスを利用する場合、当社は、ユーザーによる本サービスの利用を停止することができます。
2　本規約に同意した時点で未成年者であったユーザーが、成年に達した後に本サービスを利用した場合、未成年者であった間の利用行為を追認したものとみなされます。
3　当社は、ユーザーの年齢に応じて、本サービスの一部又は全部の利用を制限する場合があります。

第2章　SUGAR会員登録、アカウント管理

第2.1条（アカウント）
1　ユーザーは、本サービスの利用に際し、SUGAR会員登録をすることができます。ユーザーは、SUGAR会員登録をする場合には、当社所定の手続に従ってこれを行うものとします。
2　ユーザーは、SUGAR会員登録においては、真実、正確かつ完全な情報を提供しなければならず、変更があった場合でも常に最新の情報となるよう修正しなければなりません。
3　当社は、当社の判断により、SUGAR会員登録を行ったユーザーに対して、アカウントを発行します。
4　当社は、以下の各号のいずれかに該当する場合には、当社の判断により、アカウント発行を拒否することができるものとします。
(1)　本条の手続によらずにSUGAR会員登録申請をした者
(2)　反社会的勢力に該当する者
(3)　過去に当社との契約に違反した者又はその関係者であると判断される者
(4)　その他当社が不適切であると判断した者
5　ユーザーは、ユーザーID及びパスワードを不正に利用されないようご自身の責任で厳重に管理するものとします。
6　本サービスのアカウントは、ユーザーに一身専属的に帰属します。ユーザーの本サービスにおけるすべての権利義務は、第三者に譲渡、貸与、交換、売買、換金、質入又は相続させることはできません。また、方法を問わず第三者に利用させてはならないものとします。
7　ユーザーは、第三者が、ユーザーID及びパスワードを使用していることを把握した場合、又はその疑いがあると認識した場合には、直ちにその旨を当社に連絡し、当社からの指示がある場合には、これに従うものとします。
8　当社は、ユーザーのアカウントで行なわれた一切の行為を、ユーザーご本人の行為とみなすことができます。当社は、ユーザーのアカウントが使用されたことによってユーザーが被る損害について、当社に故意又は重過失がある場合を除き、一切の責任を負いません。
9　当社は、最終のアクセスから6か月利用実態のないアカウントを、あらかじめユーザーに通知することなく削除することができます。
10　ユーザーの本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。

第3章　本サービスの利用

第3.1条（本サービスの利用）
1　当社は、本サービスの全部又は一部を、アカウントの有無、年齢、ご本人確認の有無、その他当社が必要と判断する条件を満たしたユーザーに限定して提供することができるものとします。
2　当社は、法令で要求される場合を除き、有償サービスについて、ユーザーからのキャンセルによる払い戻し、アプリ内ポイントの返還、その他の償還はいたしません。

第3.2条（アプリ内ポイント）
1　当社は、本サービスの利用に際して、アプリ内ポイントを設けることがあります。
2　アプリ内ポイントは、本サービスでの購入、キャンペーンその他当社が指定する方法により、ユーザーに付与されます。アプリ内ポイントの購入単位、決済方法、利用期間、その他の付与条件は、当社が定め本アプリに表示します。
3　アプリ内ポイントは、当社が指定するサービス又はコンテンツ以外の、現金、財物その他の経済上の利益と交換することはできません。サービス又はコンテンツとの交換に必要なアプリ内ポイント数、その他アプリ内ポイントの利用条件は、当社が定め本アプリに表示します。
4　当社は、ユーザーが一度購入したアプリ内ポイントについて、法令で要求される場合を除き、一切払い戻しを行いません。法令に従って払い戻す場合の払戻方法は、法令に従って当社が定め、当社のウェブサイト等に表示します。
5　アプリ内ポイントは、ユーザーがアプリ内ポイントを購入する際に利用していたアカウントのみで使用することができ、他のアカウントに譲渡又は移転させることはできません。
6　ユーザーは、ユーザーと決済事業者との間で利用料金の支払いに関して紛争が発生した場合、当該決済事業者との間で当該紛争を処理解決するものとします。当社は、当社に故意又は重過失がある場合を除き、当該紛争について一切の責任を負わないものとします。
7　ユーザーが当社の定める期日までに当社の定める料金等を支払わなかった場合、ユーザーは、当社に対し、支払期日の翌日より年14.6パーセントの割合による遅延損害金を支払うものとします。

第3.3条（ユーザーの自己責任）
1　ユーザーは、本サービスを利用するにあたり、必要なスマートフォン、タブレット、パーソナルコンピュータ、携帯電話機、通信機器、オペレーションシステムその他本サービスの利用に必要となる機器及びサービスを、ユーザーの費用と責任で用意するものとします。
2　ユーザーは、ユーザーご自身の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為及びその結果について一切の責任を負うものとします。

第４章　月額サービスの利用

第4.1条（月額サービス）
1　月額会員は、月額利用料金を支払うことにより、プレミアムサービスなどの月額サービスを利用することができます。
2　月額サービスの利用期間（以下「月額サービス利用期間」といいます。）は、月額サービスの利用開始日（月額会員の利用料金支払の意思表示につきクレジット会社による確認がなされた日を指します。）を起算日として1か月間とします。
3　前項にかかわらず、月額会員は、月額サービス利用期間の途中であっても、第4.2条の定めに従い、いつでも月額サービス利用契約を解約することができます。
4　月額サービス利用契約の解除をしない限り、月額サービス利用契約は、1か月ごとに同条件にて自動的に更新されるものとします。

第4.2条（月額サービス利用契約の解除）
1　月額会員は、当社所定の方法によりいつでも月額サービスについて解約申請を行うことができます。但し、残期間の利用料金は返還しないものとします。
2　月額サービスを解約した月額会員は、解約の時点から月額サービスを利用することができなくなる可能性があります。
3　誤ってアカウントを削除した場合その他理由の如何を問わず、月額会員が月額サービスを利用する権利を失った場合、月額会員は、月額サービスの利用に伴い蓄積された情報を利用することができなくなることをあらかじめ承諾するものとします。
4　当社は、月額会員が月額サービスを解約した後も、当該月額会員に関し当社が取得した情報を保有・利用することができるものとします。
5　月額サービス解約後、月額会員が再度月額サービスの利用を希望する場合には、あらためて月額サービスの月額会員登録を行う必要があります。月額会員は、再登録の際に前のデータが引き継がれないことを予め承諾するものとします。

第4.3条（当社の判断による月額サービスの変更・中断・終了等）
1　当社は、当社が別途定める場合を除き、月額会員に事前の通知をすることなく、月額サービスの内容の全部又は一部を変更又は追加することができるものとします。
2　当社は、当社の判断により月額サービスの全部又は一部の提供・運営を終了することができるものとします。
3　当社は、当社の判断により月額サービスの全部又は一部の提供・運営を終了する場合、当社が適当と判断する方法で月額会員に1か月以上前にその旨を通知します。
4　サービス終了通知の1か月後、サービス終了の実施の際に残期間が残っていたとしても、利用料金は返還しないものとします。
5　当社は、以下の各号の事由が生じた場合には、月額会員に事前に通知することなく、月額サービスの一部又は全部を一時的に中断することができるものとします。
(1)　月額サービス用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合
(2)　アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合
(3)　月額会員のセキュリティを確保する必要が生じた場合
(4)　電気通信事業者の役務が提供されない場合
(5)　天災等の不可抗力により月額サービスの提供が困難な場合
(6)　火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、労働争議等により月額サービスの提供が困難な場合
(7)　法令又はこれらに基づく措置により月額サービスの運営が不能となった場合
(8)　その他前各号に準じ当社が必要と判断した場合
6　当社は、本条に基づき当社が行った措置により月額会員に生じた損害について、一切の責任を負いません。

第4.4条（プレミアムサービス提供元によるプレミアムサービスの終了）
1　「プレミアムサービス提供元」が「プレミアムサービス」を閉鎖した場合、該当プレミアムサービスは通知なしで停止されるものとします。
2　「プレミアムサービス提供元」による「プレミアムサービス」の閉鎖及び停止が実施された場合、残期間の利用料金は返還しないものとします。
3　当社は、本条に基づき当社が行った措置により月額会員に生じた損害について、一切の責任を負いません。 

第５章　権利の帰属等

第5.1条（権利の帰属等）
1　本アプリ及び本コンテンツに係る著作権等の知的財産権その他一切の権利は、当社又は当社に権利を許諾する第三者に帰属します。
2　当社は、本アプリ及び本コンテンツについて、ユーザーに対し、本サービスの利用を唯一の目的とする、譲渡不能、再許諾不可及び非独占的な利用権を付与します。ユーザーは、本アプリ及び本コンテンツを、本規約及び本サービス所定の利用態様を超えて利用（複製、送信、転載、改変などの行為を含みます。）してはなりません。
3　ユーザーは、本サービスにおいて、利用料や利用期間等の利用条件が別途定められた本コンテンツを利用する場合、かかる利用条件に従うものとします。本サービスの画面上で「購入」、「販売」などの表示がされている場合であっても、当社がユーザーに対し提供する本コンテンツに関する所有権又は著作権、商標権その他の知的財産権はユーザーに移転せず、ユーザーには、前項の利用権のみが付与されます。
4　本サービス上に表示される「SUGAR」等の企業名、商品名、サービス名その他のの名称（以下、総称して「商標等」といいます）には、当社又は第三者が商標権その他の使用権を有する名称が含まれています。ユーザーは、当社が別途定めるブランドガイドラインで認められている場合を除き、当社の事前の書面による承諾なしに、当社が商標権その他の使用権を有する名称を使用することはできません。
5　ユーザーは、本アプリ及び本コンテンツに係る知的財産権について、その登録を出願し、移転を申請し、又は行使してはならないものとします。
6　本サービスは、当社が所有又は管理しない第三者のウェブサイトへのリンクを含むことがありますが、当社は、第三者のウェブサイト上のコンテンツ、個人情報等の取扱いその他一切につき管理していません。当社は、第三者のウェブサイトを利用したことに起因してユーザーに生じた損害について、一切の責任を負わないものとします。

第5.2条（投稿コンテンツ）
1　ユーザーは、本アプリで投稿コンテンツを投稿することができます。
2　当社は、ユーザーの投稿コンテンツについて、何らの秘密保持義務を負わず、また投稿コンテンツのバックアップを行う義務を負いません。
3　ユーザーは、投稿コンテンツの投稿又は公開に伴う結果につき、ユーザーご自身が単独で責任を負うものとします。ユーザーは、投稿コンテンツについて、それを投稿又は公開するために必要なライセンス、権利、同意及び許可を有していることを保証するものとします。
4　ユーザーは、当社に対し、本アプリで投稿コンテンツを投稿又は公開するにあたり、投稿コンテンツについて、本サービスの提供、改善又は宣伝広告に必要な範囲で、非独占的に使用又は複製、翻案、公衆送信その他利用する権利を許諾するものとします。
5　本アプリは、複数のユーザーが投稿、修正、削除等の編集を行える機能を含む場合があります。この場合、ユーザーはご自身の投稿コンテンツに対する他のユーザーの編集を許諾するものとします。
6　当社は、ユーザーが投稿コンテンツに関し法令若しくは本規約に違反し、又は違反するおそれのあると認めた場合、その他業務上の必要がある場合、あらかじめユーザーに通知することなく、投稿コンテンツを削除するなどの方法により、本アプリでの投稿コンテンツの利用を制限できます。

第6章　禁止事項

第6.1条（禁止事項）
ユーザーは、本サービスの利用に際して、以下の各号に該当する行為、及びこれに該当するおそれのある行為を行なってはなりません。
(1)　法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為
(2)　公の秩序又は善良の風俗を害する行為
(3)　当社又は第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上又は契約上の権利を侵害する行為
(4)　過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引又は助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿又は送信する行為
(5)　本サービス又は本コンテンツを複写、撮影、録画、頒布等する行為
(6)　当社又は第三者になりすます行為又は意図的に虚偽の情報を流布させる行為
(7)　同一又は類似のメッセージを不特定多数のユーザーに送信する行為（当社の認めたものを除きます。）、その他当社がスパムと判断する行為
(8)　当社が定める方法以外の方法で、本コンテンツの利用権を、現金、財物その他の経済上の利益と交換する行為
(9)　営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます）、性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際及びその媒介を目的とする行為、他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
(10)　他人に対するストーカー行為
(11)　反社会的勢力に対する利益供与その他の協力行為
(12)　宗教活動又は宗教団体への勧誘行為
(13)　他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示又は提供する行為
(14)　本サービスのサーバやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、ルーチングやジェイルブレイク等改変を行った通信端末にて本サービスにアクセスする行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせ又は要求をする行為、その他当社による本サービスの運営又は他のユーザーによる本サービスの利用を妨害し、これらに支障を与える行為
(15)　過度に複数の通信機器類に本アプリを導入し、又はその他の方法により過度に複数のアカウントの付与を受ける行為
(16)　アカウント及びアプリ内ポイント等その他一切のアプリデータを貸与、交換、譲渡、名義変更、売買、質入、担保供与その他の方法により第三者に利用させ、又は処分する行為
(17)　通信妨害行為、通信傍受行為、本サービスを運営するサーバーへの侵入行為又は情報奪取行為
(18)　コンピュータウィルスその他不正な情報を送信し又は書き込む行為
(19)　不正ツール、不正アプリケーション、本アプリの海賊版、チートツールその他本サービスの不正利用を目的としたプログラムの開発、配布若しくは使用行為
(20)　本アプリの消去、改変（データの改ざんを含む）、修正、翻案、二次的著作物の作成、逆コンパイル、逆アセンブル若しくはリバースエンジニアリング、又はこれらの行為を第三者に誘発、推奨させるような一切の行為
(21)　本アプリの一部又は全部（コピーを含む）の再頒布及び貸与、並びに本アプリの再販又は転売行為
(22)　当社が禁止行為であることをユーザーに通知し、又は本アプリ上で公表した行為
(23)　上記前各号のいずれかに該当する行為を援助又は助長する行為
(24)　その他、当社が不適当と判断した行為

第7章　本サービスの中断、変更、廃止等

第7.1条（本サービスの変更、中断）
1　当社は、ユーザーに事前の通知をすることなく、本サービスの内容の全部又は一部を変更、追加することができるものとします。
2　当社は、以下の各号の事由が生じた場合には、本サービスの提供を一時的に中断することができるものとします。この場合、当社は、緊急やむを得ない場合を除き、ユーザーに事前に通知するものとします。
(1)　通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合
(2)　アクセス過多、その他予期せぬ要因で本システムに負荷が集中した場合
(3)　当社のセキュリティを確保する必要が生じた場合
(4)　法令又はこれらに基づく措置により本サービスの提供が不能となった場合
(5)　その他前各号に準じる事由が発生し、当社が必要と判断した場合
3　当社は、前二項による本サービスの内容の変更等又は本サービスの中断により、ユーザーに生じた損害について、一切の責任を負わないものとします。

第7.2条（本サービスの廃止）
当社は、本サービスの全部又は一部を廃止することができるものとします。その場合、当社は、3か月の予告期間をおいて、ユーザーにその旨を通知するものとします。

第8章　個人情報の取扱い

第8.1条（個人情報及びその扱い）
1　当社は、ユーザーが本サービスを利用するにあたり、個人情報（個人情報の保護に関する法律第2条第1項の個人情報をいいます。）その他ユーザーに関する一定の情報（以下「個人情報等」といいます。）を取得することがあります。
2　当社はユーザーから取得する個人情報等を、当社所定のプライバシーポリシーに従い取扱うものとします。

第9章　アカウントの停止等

第9.1条（アカウントの停止等）
当社は、ユーザーが本規約に違反して本サービスを利用していると認めた場合、事前にユーザーに通知することなく、以下の措置を講じることができます。ただし、当社は、かかる違反を防止又は是正する義務を負いません。
(1)　投稿コンテンツの全部又は一部の公開停止又は削除
(2)　本コンテンツの利用権の停止又は取消し
(3)　アカウントの停止又は削除
(4)　本サービスの全部又は一部の利用停止
(5)　本利用契約の全部又は一部の解除
(6)　その他当社が必要かつ適切と判断する措置

第10章　損害賠償、免責

第10.1条（ユーザーによる免責）
1　ユーザーは、本サービスの利用に関連して他のユーザーに損害を与え、又は第三者との間で紛争が生じた場合、自己の費用と責任において、かかる損害を賠償し、又は係る紛争を解決するものとし、当社に一切の迷惑や損害を与えないものとします。
2　ユーザーは、本サービスの利用に関連して当社に損害を与えた場合、当社に対して一切の損害（訴訟費用及び弁護士費用を含みます）を賠償するものとします。
3　ユーザーは、本サービスの利用に関連して、第三者が当社に対して損害賠償等の請求をした場合には、自身の費用と責任において、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、ユーザーは、当社に対して、当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。

第10.2条（当社の免責・責任の制限）
1　当社は、本サービス（本コンテンツを含みます）に事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、第三者の権利侵害などを含みます）がないことを明示的にも黙示的にも保証しておりません。当社は、ユーザーに対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
2　当社は、債務不履行、法律上の担保責任、不当利得、不法行為その他請求原因の如何にかかわらず、特別損害（予見可能性があった損害を含みます）、逸失利益、間接損害、付随的損害、派生的損害及び懲罰的損害について責任を負いません。ただし、当社に故意・重過失がある場合を除きます。
3　債務不履行、法律上の担保責任、不当利得、不法行為その他請求原因の如何にかかわらず、当社がユーザーに対して負う損害賠償額の累計総額は、1,500円を限度とします。ただし、当社に故意・重過失がある場合を除きます。

第11章　一般条項

第11.1条（不可抗力等）
当社が、以下に掲げる各号に定める事情に起因して本規約上の義務の履行を遅滞し又は履行不能となったときは、当該不履行について責任を負わないものとします。
(1)　自然災害、火災及び爆発
(2)　伝染病
(3)　戦争及び内乱
(4)　革命及び国家の分裂
(5)　公権力による命令処分
(6)　暴動
(7)　ストライキ又はロックアウト
(8)　その他前各号に準ずる事態

第11.2条（連絡方法）
1　本サービスに関する当社からユーザーへの連絡は、本アプリ又は当社が運営するウェブサイト内の適宜の場所への掲示、その他当社が適当と判断する方法により行います。
2　本サービスに関するユーザーから当社への連絡は、本アプリ又は当社が運営するウェブサイト内の適宜の場所に設置するお問い合わせフォームによる送信又は当社が指定する方法により行っていただきます。

第11.3条（権利義務の譲渡等）
ユーザーは、当社の事前の書面による承諾なく、本利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定その他の処分をしてはならないものとします。

第11.4条（規約の変更）
当社は、当社が必要と判断する場合、本規約及び個別規約を変更できるものとします。変更後の本規約及び個別規約は、本アプリ又は当社が運営するウェブサイト内の適宜の場所に掲示するものとし、掲示後7日後に効力が発生するものとします。ユーザーは本規約及び個別規約の効力発生後も本サービスを使い続けることにより、変更後の本規約に同意をしたものとみなされます。かかる変更の内容をユーザーに個別に通知することはいたしかねますので、本サービスをご利用の際には、随時、最新の本規約をご参照ください。

第11.5条（準拠法、裁判管轄）
1　本規約は日本語を正文とし、その準拠法は日本法とします。
2　本サービス又は本利用契約に起因又は関連してユーザーと当社との間に生じた紛争については東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。

以上

制定日：2019年01月01日
最終改訂日：2021年04月27日
`}
								</pre>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default Privacy


export const query = graphql`
    query {
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-02@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
